import React from 'react';

import Layout from '@layouts';
import Seo from '@layouts/common/seo';
import Knowledge from '@components/landing-page/knowledge';
import OurWriters from '@components/landing-page/our-writers';
import MakeImpact from '@components/landing-page/make-impact';
import seoImage from '@assets/landing-page/background-hero.png';
import HealthyLibrary from '@components/landing-page/healthy-library';
import LandingPageHero from '@components/landing-page/landing-page-hero';
import RecommendedBooks from '@components/landing-page/recommended-books';

const schema = {
  '@context': 'https://schema.org',
  '@type': '',
  name: 'Better way Of life',
  image: '',
  url: 'c/',
  telephone: '+1 (470) 755-6225',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '255 Arthurs Lane',
    addressLocality: 'Covington',
    addressRegion: 'GA',
    postalCode: '30016',
    addressCountry: 'us',
  },
};

const HomePage = () => {
  return (
    <>
      <Seo
        title="Bookstore: Best Healthy Living Books - Better Way of Life"
        description="Become Your Best Self by Exploring Our Healthy Living Books. Purchase Your Favorites at the Best Price from Your Favorite Bookstore Now!!"
        schemaMarkup={schema}
        seoImage={seoImage}
        siteUrl={process.env.CLIENT_URL}
      />
      <Layout>
        <LandingPageHero />
        <HealthyLibrary />
        <RecommendedBooks />
        <OurWriters />
        <MakeImpact />
        <Knowledge />
      </Layout>
    </>
  );
};

export default HomePage;
