import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { useEffect, useState, useContext } from 'react';

import SecondaryButton from '@components/reusable-buttons/secondary-button';
import LanguageContext from '../../context/language-context';
import ArrowButton from '../reusable-buttons/arrow-button';

const RecommendedBooks = () => {
  const data = useStaticQuery(graphql`
    query recommendedBooksQuery {
      allShopifyProduct {
        nodes {
          images {
            src
          }
          handle
          title
          description
          tags
        }
      }
    }
  `);

  const [stickyDiv, setStickyDiv] = useState(false);

  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      titleFirstHalf: 'Recomended',
      titleSecondHalf: 'books for you',
      viewallButton: 'View All',
      getBookButton: 'Get the book',
    },
    esp: {
      titleFirstHalf: 'Recomendado',
      titleSecondHalf: 'libros para ti',
      viewallButton: 'Ver todo',
      getBookButton: 'Consigue el libro',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
      setRecommendedBooks(espRecommendeddBooks);
    } else {
      setCurrentContent(contentResources.eng);
      setRecommendedBooks(engRecommendeddBooks);
    }
  }, [contentLanguage]);

  useEffect(() => {
    let observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          setStickyDiv(true);
        } else {
          setStickyDiv(false);
        }
      },
      { threshold: [0.5] }
    );

    observer.observe(document.getElementById('visible-div-recommended-books'));
  }, []);

  const engRecommendeddBooks = data.allShopifyProduct.nodes
    .filter((item) => item.tags.find((item) => item === 'eng') === 'eng')
    .filter((item, idx) => idx < 4);

  const espRecommendeddBooks = data.allShopifyProduct.nodes
    .filter((item) => item.tags.find((item) => item === 'esp') === 'esp')
    .filter((item, idx) => idx < 4);

  const [recommendedBooks, setRecommendedBooks] = useState([]);

  return (
    <div className="landing-recommended-books-container common-container" id="visible-div-recommended-books">
      <div className="landing-recommended-title-and-button">
        <div className={stickyDiv ? 'make-me-sticky' : 'landing-recommended-title opacity-0'} id="make-it-sticky">
          <h2>{currentContent?.titleFirstHalf}</h2>
          <h2 className="h2-modified">{currentContent?.titleSecondHalf}</h2>
          <ArrowButton buttonText={currentContent?.viewallButton} linkUrl="/books" />
        </div>
      </div>
      <div className="landing-recommended-books-list half-width" id="align-right">
        {recommendedBooks.map((item) => {
          const uid = item.handle;

          const { title, description, images } = item;
          return (
            <div className="landing-recommended-books-item ">
              <div className="recommended-books-image-holder">
                <Link to={`/books/${uid}`}>
                  <img src={images[0]?.src} />
                </Link>
              </div>
              <h5>{title}</h5>
              <p className="landing-recommended-books-desc body1">{description}</p>
              <div className="recommended-books-button">
                <Link to={`/books/${uid}`}>
                  <SecondaryButton buttonText={currentContent?.getBookButton} />
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default RecommendedBooks;
