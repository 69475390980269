import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';

import BlogButton from '../reusable-buttons/blog-button';
import LanguageContext from '../../context/language-context';
import BackGroundImage from '@assets/landing-page/background-hero.png';
import BackgroundImageSpanish from '@assets/landing-page/background-hero-spanish.png';

const LandingPageHero = () => {
  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      titleFirstHalf: 'Embrace healthy habits with ',
      titleSecondHalf: 'our healthy living bookstore.',
      desc: `Good health is the key to a happy life. Better Way of Life's medical experts uses scientific research to guide your health habits through their books. The books concentrate on the underlying causes of our society's preventable diseases. The book guides you to becoming your best self through optimal health.`,
      browseButtonText: 'Browse Books',
    },
    esp: {
      titleFirstHalf: ' Adopta hábitos saludables con nuestra',
      titleSecondHalf: ' librería de vida saludable.',
      desc: `La buena salud es la clave para una vida feliz. Los expertos médicos de Better Way of Life utilizan la investigación científica para guiar sus hábitos de salud a través de sus libros. Los libros se concentran en las causas subyacentes de las enfermedades prevenibles de nuestra sociedad. El libro lo guía para convertirse en su mejor yo a través de una salud óptima.`,
      browseButtonText: 'explorar libros',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  return (
    <div className="landing-page-hero-outermost u-margin-top-lg background-container-max-width" id="backgroundBook">
      <div className="landing-page-background-color"></div>
      <div className="landing-page-hero-container common-container">
        <div className="landing-page-hero-title">
          <h1>{currentContent?.titleFirstHalf}</h1>
          <h1 className="h1-modified"> {currentContent?.titleSecondHalf}</h1>
        </div>
        <p className="landing-page-hero-desc body2">{currentContent?.desc}</p>
        <div className="landing-page-hero-button">
          <Link to="/books">
            <BlogButton buttonText={currentContent?.browseButtonText} />
          </Link>
        </div>
      </div>
      <div className="background-image-for-hero">
        <img src={contentLanguage === 'eng' ? BackGroundImage : BackgroundImageSpanish} />
      </div>
    </div>
  );
};

export default LandingPageHero;
