import React, { useState, useEffect, useContext } from 'react';

import HotFood from '@assets/landing-page/hot-food';
import Plant from '@assets/landing-page/plant';
import Muscle from '@assets/landing-page/muscle';
import LanguageContext from '../../context/language-context';

const HealthyLibrary = () => {
  const { contentLanguage } = useContext(LanguageContext);

  const contentResources = {
    eng: {
      titleFirstHalf: 'Books on living a healthy lifestyle: ',
      titleSecondHalf: 'begin your wellness journey',
      hotFoodFirstHalfTitle: 'Healthy Never',
      hotFoodSecondHalfTitle: 'tasted Better',
      hotFoodDesc:
        'Decades of research have proven that the way we eat causes and cures many diseases. Some foods create chronic health problems, and others provide medical and protective qualities. Besides obvious health goals, food is highly culturally ingrained. It makes us part of our society and our family. Find HEALTHY and TASTY recipes to be your best you! ',
      plantFirstHalfTitle: 'Scientific encyclopedia of more',
      plantSecondtHalfTitle: 'than 470 medicinal plants',
      plantDesc:
        ' According to the World Health Organization (WHO), over 250 primary and essential drugs have plant-based origins. Daily, more and more plants are being explored and subjected to rigorous laboratory studies. Medical plants complement our other health practices. The Scientific Encyclopedia covers 470 plants and their healing powers. Learn to use the extensive research to help understand how to heal yourself and stave off disease with the power of plants. ',
      muscleFirstHalfTitle: 'Learn how to maintain',
      muscleSecondHalfTitle: 'your physical wellbeing',
      muscleDecs:
        'Our bodies are fragile, and understanding the fundamentals of our bodies will assist us in leading a healthy lifestyle. A healthier way of life will assist you in adhering to preventive measures to keep yourself healthy. Through our books, we hope to inspire you to care for yourself and those around you.',
      browseButtonText: 'Browse Books',
    },
    esp: {
      titleFirstHalf: ' Libros sobre cómo vivir un estilo de vida saludable:  ',
      titleSecondHalf: 'comience su viaje de bienestar',
      hotFoodFirstHalfTitle: 'saludable nunca ',
      hotFoodSecondHalfTitle: 'sabía mejor',
      desc: 'spanish spanish spanish spanish spanish spanish spanish spanish spanish spanish spanish spanish',
      plantFirstHalfTitle: 'Enciclopedia Científica contiene más   ',
      plantSecondtHalfTitle: 'de 470 plantas medicinales.',
      muscleFirstHalfTitle: 'Aprende a mantener ',
      muscleSecondHalfTitle: 'tu bienestar físico',
      browseButtonText: 'explorar libros',
      muscleDecs: `Nuestros cuerpos son frágiles y comprender los fundamentos de nuestros cuerpos nos ayudará a llevar un estilo de vida saludable. Una forma de vida más saludable lo ayudará a adherirse a las medidas preventivas para mantenerse saludable. A través de nuestros libros, esperamos inspirarlo a cuidar de sí mismo y de quienes lo rodean.`,
      plantDesc: `Según la Organización Mundial de la Salud (OMS), más de 250 medicamentos
        primarios y esenciales tienen origen vegetal. Diariamente, más y más plantas
        están siendo exploradas y sometidas a rigurosos estudios de laboratorio. Las
        plantas medicinales complementan nuestras otras prácticas de salud. La
        Enciclopedia Científica cubre 470 plantas y sus poderes curativos. Aprenda a
        usar la extensa investigación para ayudar a comprender cómo curarse y evitar
        enfermedades con el poder de las plantas.`,
      hotFoodDesc: `Décadas de investigación han demostrado que la forma en que comemos causa
        y cura muchas enfermedades. Algunos alimentos crean problemas de salud
        crónicos y otros brindan cualidades médicas y protectoras. Además de los
        objetivos de salud obvios, la comida está muy arraigada culturalmente. Nos hace
        parte de nuestra sociedad y nuestra familia. ¡Encuentra recetas SALUDABLES y
        SABROSAS para ser tu mejor versión!`,
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
    } else {
      setCurrentContent(contentResources.eng);
    }
  }, [contentLanguage]);
  return (
    <div className="landing-healthy-library-outermost">
      <div className="landing-healthy-library-container common-container">
        <div className="landing-healthy-library-title">
          <h2>{currentContent?.titleFirstHalf}</h2>
          <h2 className="h2-modified">{currentContent?.titleSecondHalf}</h2>
        </div>
        <div className="landing-healthy-library-suggestions">
          <div className="healthy-library-suggestion-item">
            <div className="healthy-library-suggestion-logo">
              <HotFood />
            </div>
            <h4>{currentContent?.hotFoodFirstHalfTitle}</h4>
            <h4 className="h4-modified">{currentContent?.hotFoodSecondHalfTitle}</h4>
            <p className="landing-library-desc body1">{currentContent?.hotFoodDesc}</p>
          </div>

          <div className="healthy-library-suggestion-item">
            <div className="healthy-library-suggestion-logo">
              <Plant />
            </div>
            <h4>{currentContent?.plantFirstHalfTitle} </h4>
            <h4 className="h4-modified">{currentContent?.plantSecondtHalfTitle}</h4>
            <p className="landing-library-desc body1">{currentContent?.plantDesc}</p>
          </div>
          <div className="healthy-library-suggestion-item">
            <div className="healthy-library-suggestion-logo">
              <Muscle />
            </div>
            <h4>{currentContent?.muscleFirstHalfTitle} </h4>{' '}
            <h4 className="h4-modified">{currentContent?.muscleSecondHalfTitle}</h4>
            <p className="landing-library-desc body1">{currentContent?.muscleDecs}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HealthyLibrary;
