import React from 'react';

export default function Plant() {
  return (
    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M25.7301 9.91487C20.1389 4.32653 9.19766 3.67853 1.45307 3.67853C0.651001 3.67853 0 4.32939 0 5.13146C0 12.876 0.645144 23.8173 6.23634 29.4087C8.5961 31.7684 12.2141 32.8233 15.8002 32.8233C19.6972 32.8233 23.5593 31.5766 25.7301 29.4058C29.8946 25.2414 30.6589 14.8406 25.7301 9.91487ZM23.6757 27.354C20.5661 30.4606 11.9323 30.9924 8.29397 27.354C3.78082 22.841 2.99905 13.6724 2.91473 6.59325C9.99387 6.67757 19.1625 7.45921 23.6755 11.9724C27.314 15.6107 26.7822 24.2475 23.6757 27.354Z"
        fill="#F15E32"
      />
      <path
        d="M62.5466 9.4267C54.802 9.4267 43.8607 10.0718 38.2694 15.663C33.3408 20.5888 34.105 30.9896 38.2694 35.154C40.4402 37.3219 44.2995 38.5686 48.1965 38.5686C51.7825 38.5686 55.3977 37.5166 57.7632 35.157C63.3515 29.5658 63.9995 18.6245 63.9995 10.8798C63.9997 10.0777 63.3487 9.4267 62.5466 9.4267ZM55.7029 33.0994C52.0645 36.7377 43.4277 36.2059 40.3212 33.0994C37.2146 29.9928 36.6828 21.356 40.3212 17.7177C44.8343 13.2045 54.0028 12.4229 61.082 12.3386C60.9978 19.4206 60.216 28.5892 55.7029 33.0994Z"
        fill="#F15E32"
      />
      <path
        d="M52.0528 22.0041C51.7099 21.2746 50.7277 21.0248 50.0041 21.3618C38.8594 26.6014 33.3844 35.7381 30.8967 45.1304C27.8192 25.1599 18.918 19.0718 15.143 16.4884C14.6722 16.1658 14.3061 15.9304 14.1027 15.7299C13.536 15.1632 12.6147 15.1632 12.0481 15.7299C11.4814 16.2966 11.4814 17.2178 12.0481 17.7845C12.3358 18.0693 12.8327 18.4268 13.504 18.8859C17.7585 21.7949 29.06 29.5279 29.06 60.3466C29.06 61.1486 29.711 61.7996 30.5131 61.7996C31.3152 61.7996 31.9662 61.1486 31.9662 60.3496C31.9662 47.8972 35.3111 31.4809 51.242 23.9948C51.242 23.992 51.4774 23.8815 51.4774 23.8815C52.201 23.5385 52.3957 22.7306 52.0528 22.0041Z"
        fill="#F15E32"
      />
    </svg>
  );
}
