import React, { useState, useContext, useEffect } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';

import SecondaryButton from '@components/reusable-buttons/secondary-button';
import LanguageContext from '../../context/language-context';

const Knowledge = () => {
  const data = useStaticQuery(graphql`
    query KnowledgeBlogs {
      allPrismicBlogDetails {
        nodes {
          data {
            excerpt {
              text
            }
            title {
              text
            }
            language
            featuredImage: featured_image {
              fluid {
                src
              }
            }
            tags {
              text
            }
          }
          uid
        }
      }
    }
  `);

  // const fromKnowledgeBlogs = data.allPrismicBlogDetails.nodes.filter((item, idx) => idx < 2);
  const engFromKnowledgeBlogs = data.allPrismicBlogDetails.nodes
    .filter((item, idx) => item.data.language === 'English')
    .slice(0, 2);
  const espFromKnowledgeBlogs = data.allPrismicBlogDetails.nodes
    .filter((item, idx) => item.data.language === 'Spanish')
    .slice(0, 2);

  const { contentLanguage } = useContext(LanguageContext);

  const [fromKnowledgeBlogs, setFromKnowledgeBlogs] = useState([]);
  const contentResources = {
    eng: {
      titleFirstHalf: '360 degree ',
      titleSecondHalf: 'wellness knowledge',

      exploreBlogButton: 'Explore Blogs',
    },
    esp: {
      titleFirstHalf: 'Del ',
      titleSecondHalf: 'conocimiento',

      exploreBlogButton: 'Explorar Blogs',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
      setFromKnowledgeBlogs(espFromKnowledgeBlogs);
    } else {
      setCurrentContent(contentResources.eng);
      setFromKnowledgeBlogs(engFromKnowledgeBlogs);
    }
  }, [contentLanguage]);

  return (
    <div className="landing-knowledge-holder common-container u-margin-top-lg u-margin-bottom-lg">
      <div className="landing-knowledge-heading">
        <span>
          <h3>{currentContent?.titleFirstHalf} </h3>
          <h3 className="h3-modified">{currentContent?.titleSecondHalf}</h3>
        </span>
        <div className="landing-knowledge-explore">
          <Link to="/blog">
            <SecondaryButton buttonText={currentContent?.exploreBlogButton} />
          </Link>
        </div>
      </div>
      <div className="landing-knowledge-inner">
        {fromKnowledgeBlogs.map((item) => {
          const UID = item.uid;
          const { featuredImage, tags, title } = item.data;
          return (
            <div className="landing-knowledge-info half-width">
              <div className="landing-knowledge-info-image">
                <Link to={`/blog/${UID}`}>
                  <img src={featuredImage?.fluid.src} />
                </Link>
              </div>
              <h6>{tags?.text}</h6>
              <h4>{title?.text}</h4>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default Knowledge;
