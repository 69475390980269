import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState, useContext } from 'react';

import LanguageContext from '../../context/language-context';
import ArrowButton from '../reusable-buttons/arrow-button';

const OurWriters = () => {
  const data = useStaticQuery(graphql`
    query ourAuthorsQuery {
      allPrismicAuthor {
        nodes {
          data {
            designation {
              text
            }
            headshot {
              fluid {
                src
              }
            }
            title {
              text
            }
            language
          }
        }
      }
    }
  `);

  const [stickyDiv, setStickyDiv] = useState(false);
  const [allWriters, setAllWriters] = useState([]);
  const { contentLanguage } = useContext(LanguageContext);

  const ourWriters = data.allPrismicAuthor.nodes;
  const ourEngWriters = ourWriters.filter((item) => item.data.language === 'eng').slice(0, 4);
  const ourEspWriters = ourWriters.filter((item) => item.data.language === 'esp').slice(0, 4);

  const contentResources = {
    eng: {
      titleFirstHalf: 'Our doctors, ',
      titleSecondHalf: 'researchers & authors',
      viewallButton: 'View All',
    },
    esp: {
      titleFirstHalf: 'Nuestros escritores y',
      titleSecondHalf: 'editores',
      viewallButton: 'Ver todo',
    },
  };
  const [currentContent, setCurrentContent] = useState(contentResources.eng);

  useEffect(() => {
    if (contentLanguage === 'esp') {
      setCurrentContent(contentResources.esp);
      setAllWriters(ourEspWriters);
    } else {
      setCurrentContent(contentResources.eng);
      setAllWriters(ourEngWriters);
    }
  }, [contentLanguage]);

  useEffect(() => {
    let observer = new IntersectionObserver(
      function (entries) {
        if (entries[0].isIntersecting === true) {
          setStickyDiv(true);
        } else {
          setStickyDiv(false);
        }
      },
      { threshold: [0.9] }
    );

    observer.observe(document.getElementById('visible-writer-div'));
  }, []);

  return (
    <div className="landing-recommended-books-container common-container" id="visible-writer-div">
      <div className="landing-recommended-title-and-button">
        <div
          className={stickyDiv ? 'make-me-sticky' : 'landing-recommended-title opacity-0-writer'}
          id="sticky-writers"
        >
          <h2>{currentContent.titleFirstHalf}</h2>
          <h2 className="h2-modified" id="scrollingContent">
            {currentContent.titleSecondHalf}
          </h2>
          <ArrowButton buttonText={currentContent.viewallButton} linkUrl="/about-us#writers-and-publishers" />
        </div>
      </div>

      <div className="landing-recommended-books-list half-width" id="right-align">
        {allWriters.map((item) => {
          const { designation, title, headshot } = item.data;
          return (
            <div className="landing-recommended-books-item our-writer-height ">
              <div className="recommended-books-image-holder writer-image-holder">
                <img src={headshot?.fluid.src} />
              </div>
              <h5>{title?.text}</h5>
              <p className="landing-recommended-books-desc body1 writer-designation">{designation?.text}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default OurWriters;
