import React from 'react';

export default function HotFood() {
  return (
    <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M62.125 30.864H1.875C0.8395 30.864 0 31.7035 0 32.739C0 42.583 5.976 51.4543 15 55.2175V58.989C15 60.0245 15.8395 60.864 16.875 60.864H47.125C48.1605 60.864 49 60.0245 49 58.989V55.2175C58.0685 51.4356 64 42.5369 64 32.739C64 31.7035 63.1605 30.864 62.125 30.864ZM60.1641 34.614C60.0465 35.8954 59.8079 37.1494 59.461 38.364H4.539C4.19212 37.1494 3.9535 35.8954 3.83588 34.614H60.1641ZM46.4992 52.1636C45.7505 52.4288 45.25 53.1369 45.25 53.9311V57.114H18.75V53.9311C18.75 53.1369 18.2495 52.4288 17.5007 52.1636C12.4235 50.3661 8.37712 46.7023 6.02175 42.114H57.9782C55.623 46.7023 51.5765 50.3661 46.4992 52.1636Z"
        fill="#F15E32"
      />
      <path
        d="M19.4244 22.8149C20.1565 23.5471 21.3437 23.5473 22.076 22.8149C24.8749 20.016 24.8749 15.4621 22.076 12.6633C20.7362 11.3234 20.7361 9.15489 22.076 7.81489C22.8082 7.08264 22.8082 5.89551 22.076 5.16326C21.3439 4.43101 20.1566 4.43101 19.4244 5.16326C16.6255 7.96214 16.6255 12.516 19.4244 15.3149C20.7641 16.6548 20.7642 18.8233 19.4244 20.1633C18.6921 20.8955 18.6921 22.0826 19.4244 22.8149Z"
        fill="#F15E32"
      />
      <path
        d="M30.6744 22.8149C31.4065 23.5471 32.5937 23.5473 33.326 22.8149C36.1248 20.016 36.1248 15.4621 33.326 12.6633C31.9862 11.3234 31.9861 9.15489 33.326 7.81489C34.0582 7.08264 34.0582 5.89551 33.326 5.16326C32.5939 4.43101 31.4066 4.43101 30.6744 5.16326C27.8755 7.96214 27.8755 12.516 30.6744 15.3149C32.0141 16.6548 32.0142 18.8233 30.6744 20.1633C29.9421 20.8955 29.9421 22.0826 30.6744 22.8149Z"
        fill="#F15E32"
      />
      <path
        d="M41.9244 22.8149C42.6565 23.5471 43.8437 23.5473 44.576 22.8149C47.3748 20.016 47.3748 15.4621 44.576 12.6633C43.2363 11.3235 43.2361 9.15489 44.576 7.81489C45.3082 7.08264 45.3082 5.89551 44.576 5.16326C43.8438 4.43101 42.6566 4.43101 41.9244 5.16326C39.1255 7.96214 39.1255 12.516 41.9244 15.3149C43.264 16.6546 43.2642 18.8234 41.9244 20.1633C41.192 20.8955 41.192 22.0826 41.9244 22.8149Z"
        fill="#F15E32"
      />
    </svg>
  );
}
